import {break_sm, break_xs} from "./js/constants";

if (NODE_ENV == 'development') {
    import('./pug/index.pug')
    import('./pug/plug.pug')
    import('./pug/components.pug')
    import('./pug/pdf.pug')
}

// import styles
//=====================================
import "swiper/dist/css/swiper.min.css"
import 'simplebar/dist/simplebar.css'
import 'tingle.js/src/tingle.css'
import './sass/main.sass'

// import components
//=====================================
// import './components/toTop'
import './components/sliderPlyers'
import './components/cases'
import './components/persons'
import './components/popup-mobile'
import './components/player'

import {popupBottomMobile} from './components/popup-bottom-mobile';
popupBottomMobile();

// import scripts
//=====================================
import './js/dinamic-links.js'
import './js/forms.js'
import './js/lazy-video.js'
import './js/video-slider.js'
import './js/slider-channels.js'
import './js/b-toggle.js'
import './js/modals.js'
import './components/popup-mobile/popup-mobile.js'
import './js/calc-form.js'

// import icons
//=====================================
import './js/svg-sprite'

import {forEach, mobileDevice, Move} from './js/helpers'

// // // Import any polyfill to enable smoothscroll for JS APIs
// import smoothscrollPolyfill from 'smoothscroll-polyfill';
// // // Import this package to apply the smoothscroll to anchor links
// // import smoothscrollAnchorPolyfill from 'smoothscroll-anchor-polyfill';
// // // (Unlike this package, smoothscroll-polyfill needs to be actively invoked: )
// smoothscrollPolyfill.polyfill();


// ;(function() {
//     const header = document.querySelector('.header')
//     forEach(document.querySelectorAll('.nav-main a'), item => {
//         item.addEventListener('click', e => {
//             e.preventDefault()
//             window.scroll(0, document.querySelector(decodeURI(item.hash)).offsetTop - header.offsetHeight);
//         })
//     })
// }());

if(location.hostname == 'localhost') {
  document.documentElement.classList.add('css-simple-grid')
}
if(location.pathname.indexOf('/pdf.html') !== -1) {
    import(/* webpackChunkName: "pdf" */ './sass/partials/pdf.sass')
}

if (mobileDevice()) {
    document.body.classList.add('is-mobile')
}

if (document.querySelector('.tabs-side__nav')) {
    new Tabby('.tabs-side__nav');
}
if (document.querySelector('.tabs-voices__nav')) {
    new Tabby('.tabs-voices__nav');
}

(function () {
    forEach(document.querySelectorAll('.player3-wrapper'), wrapper => {
        const button = wrapper.querySelector('.button-more');
        button.addEventListener('click', () => {
            wrapper.classList.toggle('active');
        })
    })
})();

import ActiveMenuLink from "active-menu-link";

(function () {
    const header = document.querySelector('.header');
    const navMain = document.querySelector('.nav-main');
    if (!navMain) return;
    new ActiveMenuLink(".nav-main", {
      activeClass: "active",
      itemTag: "li",
      // scrollOffset: header.offsetHeight,
      headerHeight: header.offsetHeight
    });
})();

new Move(document.querySelector('[data-move="b-calc-bottom-get"]'), document.querySelector('[data-move="b-calc-bottom-set"]'), break_sm).init();
new Move(document.querySelector('[data-move="b-times-get"]'), document.querySelector('[data-move="b-times-set"]'), break_sm).init();
new Move(document.querySelector('[data-move="group-1-get"]'), document.querySelector('[data-move="group-1-set"]'), break_sm).init();
new Move(document.querySelector('[data-move="b-cases-content__bottom-get"]'), document.querySelector('[data-move="b-cases-content__bottom-set"]'), break_xs).init();

(function initYoutubeApi() {
    const tag = document.createElement('script');
    const firstScriptTag = document.getElementsByTagName('script')[0];
    tag.src = 'https://www.youtube.com/iframe_api';
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => {
        console.log('onYouTubeIframeAPIReady');
        document.dispatchEvent(new CustomEvent('onYouTubeIframeAPIReady'))
    }
})();

document.addEventListener('DOMContentLoaded', () => {
    let currentUrl = document.URL;
    let url = new URL(currentUrl);

    // console.log(currentUrl)
    // console.log(url)
    // console.log(url.searchParams.size)
    // console.log('new:', Array.from(url.searchParams).length)

    if (Array.from(url.searchParams).length > 0) {
        // console.log('url.searchParams.size > 0: ', url.searchParams.size > 0)
        if (url.searchParams.has('rolik')) {
            // console.log('rolik has')
            if (document.getElementById('modal-clip')) {
                // console.log('modal clip has')
                document.querySelectorAll('.new-modal.new-modal--active').forEach((child) => child.classList.remove('new-modal--active'))
                document.getElementById('modal-clip').classList.add('new-modal--active')
                document.body.classList.add('scroll-disabled')
            }
        }
        if (url.searchParams.has('project')) {
            if (document.getElementById('modal-brif')) {
                document.querySelectorAll('.new-modal.new-modal--active').forEach((child) => child.classList.remove('new-modal--active'))
                document.getElementById('modal-brif').classList.add('new-modal--active')
                document.body.classList.add('scroll-disabled')
            }
        }
        if (url.searchParams.has('new-brif')) {
            if (document.getElementById('modal-brif-new')) {
                document.querySelectorAll('.new-modal.new-modal--active').forEach((child) => child.classList.remove('new-modal--active'))
                document.getElementById('modal-brif-new').classList.add('new-modal--active')
                document.body.classList.add('scroll-disabled')
            }
        }
    }
})

document.addEventListener('DOMContentLoaded', () => {
    // load file
    const file = document.querySelectorAll('.file')
    const fileInput = document.querySelectorAll('.file__input')

    let uploadedFiles = [];

    if (file && fileInput) {
        file.forEach(item => {
            item.addEventListener('click', (event) => {

                if (event.target.classList.contains('file__loaded-delete') || event.target.closest('.file__loaded-delete')) {
                    event.preventDefault()

                    const parent = event.target.closest('.file__loaded-item')

                    parent.remove()
                }
            })
        })

        fileInput.forEach(item => {
            item.addEventListener('change', (event) => {

                handleFileSelect(event)
            })
        })

        function handleFileSelect(event) {
            const files = event.target.files;
        
            const fileList = event.target.closest('.file')
        
            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                if (file.size > 5 * 1024 * 1024) {
                    console.log(`Файл ${file.name} превышает максимально допустимый размер (5 МБ) и не будет загружен.`);
                    return false;
                }

                uploadedFiles.push(file);

                // list item
                const listItem = document.createElement('div');
                listItem.className = 'file__loaded-item';

                // file name
                const fileName = document.createElement('span');
                fileName.className = 'file__loaded-name';
                fileName.innerHTML = `${file.name}`;
        
                // delete button
                const deleteButton = document.createElement('button');
                deleteButton.className = 'file__loaded-delete';
                deleteButton.innerHTML = `
                    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.8" clip-path="url(#clip0_1066_1176)">
                    <path d="M0.24 8.26C0.4 8.42 0.56 8.5 0.8 8.5C1.04 8.5 1.2 8.42 1.36 8.26L4 5.62L6.64 8.26C6.8 8.42 7.04 8.5 7.2 8.5C7.36 8.5 7.6 8.42 7.76 8.26C8.08 7.94 8.08 7.46 7.76 7.14L5.12 4.5L7.76 1.86C8.08 1.54 8.08 1.06 7.76 0.74C7.44 0.42 6.96 0.42 6.64 0.74L4 3.38L1.36 0.74C1.04 0.42 0.56 0.42 0.24 0.74C-0.08 1.06 -0.08 1.54 0.24 1.86L2.88 4.5L0.24 7.14C-0.08 7.46 -0.08 7.94 0.24 8.26Z" fill="#212535"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1066_1176">
                    <rect width="8" height="8" fill="white" transform="translate(0 0.5)"/>
                    </clipPath>
                    </defs>
                    </svg>
                `;
                deleteButton.onclick = function() {
                    listItem.remove();
                    uploadedFiles = uploadedFiles.filter(uploadedFile => uploadedFile !== file);
                };
        
                listItem.appendChild(fileName);
                listItem.appendChild(deleteButton);
                fileList.querySelector('.file__loaded').appendChild(listItem);
            }

            if (uploadedFiles.length == 0) {
                if (fileList.classList.contains('is--loaded')) {
                    fileList.classList.remove('is--loaded')
                }
            } else {
                if (!fileList.classList.contains('is--loaded')) {
                    fileList.classList.add('is--loaded')
                }
            }
            
            getUploadedFiles()
        }

        function getUploadedFiles() {
            console.log(uploadedFiles);
        }
    }
})
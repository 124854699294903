import { forEach } from './helpers'
import {controls} from "./controls";

(function() {

    const selectEls = document.body
    // let itemChecker = ''

    const useItemChecker = (els, onClickOutside) => {
        const checkBodyClick = (e) => {
            let currentEl = e.target;
    
            while (currentEl) {
                if (els.includes(currentEl)) break;
                currentEl = currentEl.parentNode
            }
    
            if (!currentEl) {
                onClickOutside()
                removeBodyChecker()
            }
        }
    
        function setBodyChecker() {
            document.documentElement.addEventListener('click', checkBodyClick)
        }
    
        function removeBodyChecker() {
            document.documentElement.removeEventListener('click', checkBodyClick)
        }
    
        return {setBodyChecker, removeBodyChecker}
    }

    function init(form) {
        if (!form) return;
        controls(form);

        forEach(form.querySelectorAll('input.form-control'), control => {
            // console.log('change')
            control.addEventListener('change', () => {
                console.log('control changed');
                updateCalcSum(form);
            });
            control.addEventListener('counter-changed', () => {
                console.log('counter changed');
                updateCalcSum(form);
            })
        });

        // forEach(form.querySelectorAll('#city-select, #type-select, #period-select'), select => {
        //     select.addEventListener('change', () => selectChanged(form, select))
        // });

        forEach(form.querySelectorAll('.js-select-time-choose'), select => {
            const parentCalcForm = select.closest('.calc-form')
            const timesEls = parentCalcForm.querySelectorAll('.b-times');
            // console.log('nodelist:', timesEls)
            select.addEventListener('change', () => {
                forEach(timesEls, el => {
                    el.classList.remove('active')
                });
                timesEls[select.value].classList.add('active')
            })
        });

        form.querySelectorAll('.b-counter').forEach(item => {
            // console.log('counter init')
            const form = item.closest("form");
            // console.log(item);
            const input = item.querySelector('input');
            const setInputWidth = () => input.value.length * 9 + 25 + 'px';

            input.addEventListener('keyup', () => {
                input.style.width = setInputWidth()
            })
            function setInputWidthFunc() {
            if (input.classList.contains('has-type')) {
                input.style.width = setInputWidth();
            }
            }
            setInputWidthFunc()
            input.addEventListener('change', () => {
                const min_val = input.getAttribute("min");
                if (parseInt(input.value) < min_val) {
                    input.value = min_val
                }
                setInputWidthFunc()
            });
            item.querySelectorAll("[data-button]").forEach(button => {
                button.addEventListener('click', () => {
                    // console.log(button)

                    const max_val = input.getAttribute("max");
                    input.value = (parseInt(input.value) + parseInt(button.dataset.button + 5)).toString();
                    if (parseInt(input.value) < 0) {
                        input.value = "0"
                    }
                    if (parseInt(input.value) > max_val) {
                        input.value = String(parseInt(max_val) - 1)
                    }
                    // console.log('click')
                    setInputWidthFunc()
                    input.dispatchEvent(new CustomEvent('counter-changed'));
                    input.dispatchEvent(new Event('change'));
                })
            })
        });

        forEach(document.querySelectorAll('[data-modal]'), button => {
            button.addEventListener('click', e => {
                e.preventDefault()
                button.dispatchEvent(new Event(`eventModal${button.dataset.modal}`, {bubbles: true}));
            })
        });

        // itemChecker = useItemChecker([selectEls.querySelectorAll('.select-box').parentNode, ...selectEls.querySelectorAll('.select-box__selected')], close)

        const modalBtn = document.querySelectorAll('.new-modal-btn')

        if (modalBtn) {
            // modal btn
            modalBtn.forEach((item, i) => {
                item.addEventListener('click', (event) => {
                    event.preventDefault();

                    const modalID = item.dataset.id

                    document.querySelectorAll('.new-modal.new-modal--active').forEach((child) => child.classList.remove('new-modal--active'))
                    document.getElementById(modalID).classList.add('new-modal--active')
                    document.body.classList.add('scroll-disabled')
                });
            });
        }
    };

    init(document.querySelector('.form-calculator'));

    function updateCalcSum(form) {
        // console.log('update')
        //получить значения для формулы
        const chronEl = form.querySelector("#chron");
        if (!chronEl) return;
        var chron = chronEl.value;

        var perday = form.querySelector("#perday").value;
        // var time = form.querySelector("#period-select").selectedIndex;
        // var price = form.querySelector("#period-select").options[time].dataset.price;
        var time = form.querySelector("#period-select").querySelector('input[type=checkbox]:checked');
        var price = time.dataset.price;

        var nds = form.querySelector("#nds").value;
        // var city = form.querySelector("#city-select").selectedIndex;
        // var city_coef = form.querySelector("#city-select").options[city].dataset.coef;
        var city = form.querySelector("#city-select").querySelector('input[type=checkbox]:checked');
        var city_coef = city.dataset.coef;
        
        var period = form.querySelector("#period").value;
        // var type = form.querySelector("#type-select").selectedIndex;
        // var type_coef = form.querySelector("#type-select").options[type].dataset.coef;
        var type = form.querySelector("#type-select").querySelector('input[type=checkbox]:checked');
        var type_coef = type.dataset.coef;


        // console.log(chron,perday / 60, price, nds, city_coef, period, type_coef)
        var sum = chron * perday / 60 * price * nds * city_coef * period * type_coef;
        var sum_int = chron * perday * period;
        var sum_sale = 0;
        
        forEach(form.querySelectorAll('.sale_value'), sale => {
            var min_time = sale.dataset.min;
            var max_time = sale.dataset.max;
            var val = sale.dataset.val;
            if (sum_int >= min_time && sum_int <= max_time) sum_sale = val;
        });
        
        form.querySelector(".calc-form-order__discount").innerHTML = "-" + sum_sale + "%";
        var itog = sum/100*(100 - sum_sale);
        form.querySelector(".calc-form-order__newprice").innerHTML = itog.toFixed() + "<span>BYN</span>";
        form.querySelector(".calc-form-order__oldprice span").innerHTML = sum.toFixed() + " BYN";
    }
    
    function selectChanged(form, select) {
        // var city_id_ind = form.querySelector("#city-select").selectedIndex;
        // var city_id = form.querySelector("#city-select").querySelectorAll('option')[city_id_ind].dataset.id;
        var city_id_ind = form.querySelector("#city-select").querySelector('input[type=checkbox]:checked');
        var city_id = city_id_ind.dataset.id;
    
        // var type_id_ind = form.querySelector("#type-select").selectedIndex;
        // var type_id = form.querySelector("#type-select").querySelectorAll('option')[type_id_ind].dataset.id;
        var type_id_ind = form.querySelector("#type-select").querySelector('input[type=checkbox]:checked');
        var type_id = type_id_ind.dataset.id;
    
        // var period_id_ind = form.querySelector("#period-select").selectedIndex;
        // var period_id = form.querySelector("#period-select").querySelectorAll('option')[period_id_ind].dataset.id;
        var period_id_ind = form.querySelector("#period-select").querySelector('input[type=checkbox]:checked');
        var period_id = period_id_ind.dataset.id;
    
        console.log(city_id, type_id, period_id)
    
        var data = "";
        if (select.id === "city-select") {
            data =  "CITY_ID="+city_id+"&TYPE_ID="+type_id;
        } else {
            data =  "CITY_ID="+city_id+"&TYPE_ID="+type_id+"&PERIOD_ID="+period_id;
        }
        // console.log(data)
        init(document.querySelector('.form-calculator'));
        var request = new XMLHttpRequest();
        request.open('POST', '/bitrix/templates/partnership/calc.php', true);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        request.onload = function() {
            if (this.status >= 200 && this.status < 400) {
                // Success!
                const formParent = form.parentNode;
                formParent.innerHTML = this.response;
                init(formParent.children[0]);
            }
        };
        request.send(data);
    }
    
    if (selectEls) {
        // let cSelects = selectEls.querySelectorAll('.select-box')

        // if (cSelects) {}

        const close = () => {
            document.querySelectorAll('.select-box__container').forEach((child) => {
                child.classList.remove('select-box__container--active')
                child.removeAttribute('style')
            })
            document.querySelectorAll('.select-box__selected').forEach((child) => child.classList.remove('select-box__selected--active'))
        }
        // itemChecker = useItemChecker([selectEls.querySelectorAll('.select-box').parentNode, ...selectEls.querySelectorAll('.select-box__selected')], close)
    
        selectEls.addEventListener('click', (event) => {
            let select = event.target.closest('.select-box__selected');
            let option = event.target.closest('.select-box__option');
            
            console.log(event.target)
            const itemChecker = useItemChecker([event.target], close)
    
            if (select && selectEls.contains(select)) {
                event.preventDefault()

                let container = select.previousElementSibling
        
                if (container.classList.contains('select-box__container--active')) {
                    close()
                }
                else {
                    close()
                    
                    container.classList.add('select-box__container--active')
                    select.classList.add('select-box__selected--active')
                    itemChecker.setBodyChecker()
                }
            }
    
            if (option && selectEls.contains(option)) {
                event.preventDefault()
    
                let parent = option.closest('.select-box')
                let currentSelectedPlaceholder = parent.querySelector('.select-box__placeholder')
        
                parent.querySelectorAll('.select-box__radio').forEach(checkbox => checkbox.checked = false)
                option.querySelector('input').checked = true
        
                currentSelectedPlaceholder.innerHTML = option.querySelector('.select-box__title').innerHTML
        
                document.querySelectorAll('.select-box__container').forEach((child) => {
                    child.classList.remove('select-box__container--active')
                    child.removeAttribute('style')
                })
                document.querySelectorAll('.select-box__selected').forEach((child) => child.classList.remove('select-box__selected--active'))
    
                if (selectEls.querySelector('.form-calculator')) {
                    selectChanged(selectEls.querySelector('.form-calculator'), event.target.closest('.select-box'))
                }
            }
        })
    }

}());



import validate from './validate.js'
import {forEach} from './helpers.js'
import {controls} from './controls.js'
import {counter} from './b-counter.js';


controls(document.forms);
validate('.contact-form');
// validate('.all-validate-form');

function star(string, token) {
    return string.replace(new RegExp(token, 'g'), '.');
}

forEach(document.querySelectorAll('.all-validate-form'), item => {
    let temp = '.' + star(item.className, ' ')
    console.log('' + temp + '')
    validate(''+ temp + '');
})
controls(document.querySelectorAll('.all-validate-form'));


counter(document.querySelectorAll('.b-counter'));